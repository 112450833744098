import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { colors, standardWrapper, Nav1White } from "../styles/helpers"
import AltLogo from "./Logos/AltLogo"

import Youtube from "./Icons/Youtube"
// import Instagram from "./Icons/Instagram"
import Linkedin from "./Icons/Linkedin"

const getData = graphql`
  {
    socialIcons: wp {
      siteWideSettings {
        acfSiteWideSettings {
          linkedinUrl
          instagramUrl
          youtubeUrl
        }
      }
    }

    footerMenu: wpMenu(name: { eq: "Footer Menu" }) {
      name
      menuItems {
        nodes {
          label
          url
          parentDatabaseId
          parentId
          id
          databaseId
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(getData)
  const { footerMenu } = data
  return (
    <FooterStyled>
      <div className="wrapper">
        <div className="footerSocial">
          <h3>Follow Us</h3>
          <ul>
            {/* <StyledIcon>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  data.socialIcons.siteWideSettings.acfSiteWideSettings
                    .linkedinUrl
                }
              >
                <Instagram />
              </a>
            </StyledIcon> */}
            <StyledIcon>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  data.socialIcons.siteWideSettings.acfSiteWideSettings
                    .linkedinUrl
                }
              >
                <Linkedin />
              </a>
            </StyledIcon>
            <StyledIcon>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  data.socialIcons.siteWideSettings.acfSiteWideSettings
                    .youtubeUrl
                }
              >
                <Youtube />
              </a>
            </StyledIcon>
          </ul>
        </div>
        <div className="footerNav">
          <ul>
            {footerMenu.menuItems.nodes.map(item => {
              const slug = item.url.split("/").join("")
              return (
                <li key={item.id}>
                  <Link to={`/${slug}`}>{item.label}</Link>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="footerLogo">
          <AltLogo />
        </div>
        <div className="footerCopy">
          <p>
            Lochsmith Consulting © {new Date().getFullYear()} All Rights
            Reserved. Designed, and developed by{" "}
            <a
              title="Switchback Creative - Link will open in new window"
              target="_blank"
              rel="noreferrer"
              href="https://switchbackcreative.ca"
            >
              Switchback Creative
            </a>
            , Built with ♡ and
            {` `}
            <a
              title="Gatsby JS - Link will open in new window"
              target="_blank"
              rel="noreferrer"
              href="https://www.gatsbyjs.com"
            >
              Gatsby
            </a>
          </p>
        </div>
      </div>
    </FooterStyled>
  )
}

const FooterStyled = styled.footer`
  background-color: ${colors.colorPrimary};

  .wrapper {
    ${standardWrapper};
  }

  .footerSocial {
    width: 100%;
    margin-bottom: 2.5rem;
    text-align: center;

    @media (min-width: 768px) {
      width: 25%;
      margin-bottom: 0;
    }

    h3 {
      ${Nav1White};
      margin-bottom: 2rem;
      text-transform: uppercase;
    }
  }

  .footerNav {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      width: 30%;
      text-align: left;
    }

    ul {
      width: 100%;

      li {
        a {
          ${Nav1White};
          margin-bottom: 0.5rem;
          text-transform: uppercase;

          &[aria-current="page"] {
            color: ${colors.colorTertiary};

            &:hover {
              color: ${colors.colorTertiary};
              cursor: default;
            }
          }
        }
      }
    }
  }

  .footerLogo {
    width: 100%;
    max-width: 25rem;
    margin-top: 2.5rem;

    @media (min-width: 768px) {
      width: 35%;
      max-width: 100%;
      margin-top: 0;
      margin-left: 5%;
    }

    @media (min-width: 1025px) {
      margin-left: 10%;
    }
  }

  .footerCopy {
    margin-top: 2rem;
    p {
      ${Nav1White};
      margin: 0;

      &:hover {
        color: ${colors.white};
      }
    }

    a {
      ${Nav1White};
    }
  }
`

const StyledIcon = styled.li`
  display: inline-block;
  margin-right: 1rem;
  margin-left: 1rem;

  &:first-of-type {
    margin-left: 0;
  }

  a {
    display: block;
    position: relative;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    @media (min-width: 768px) {
      width: 3rem;
      height: 3rem;
    }
    @media (min-width: 1025px) {
      width: 3rem;
      height: 3rem;
    }

    svg {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: auto;
      transition: all 0.3s ease-out;
      fill: ${colors.white};

      @media (min-width: 768px) {
        width: 3rem;
        height: 3rem;
      }
      @media (min-width: 1025px) {
        width: 3rem;
        height: 3rem;
      }

      &:hover {
        fill: ${colors.colorTertiary};
      }
    }
  }
`

export default Footer
