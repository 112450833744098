const themeColors = {
  colorPrimary: "#003260",
  colorSecondary: "#358285",
  colorTertiary: "#d89f16",
  colorAccent: "#003260",
  colorShad: "#090b10",
  colorAlt: "#090b10",
  white: "rgba(245,245,245, 1)",
  grey: "rgba(226,232,233, 1)",
  greyMed: "rgba(120,119,124, 1)",
  greyBrown: "rgba(67,67,67, 1)",
  black: "rgba(0,0,0, 1)",
  strongred: "rgba(255, 0, 0, 1)",
  open: "#4bb1cf",
  fulfilled: "#15cd72",
  unfulfilled: "#ede04d",
  cancelled: "#ffcc00",
  shortcancelled: "#ff9966",
  error: "#ed4f32",
}

export default themeColors
