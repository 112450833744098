import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { colors } from "../../../styles/helpers"

import Youtube from "../../Icons/Youtube"
// import Instagram from "../../Icons/Instagram"
import Linkedin from "../../Icons/Linkedin"

const getData = graphql`
  {
    socialIcons: wp {
      siteWideSettings {
        acfSiteWideSettings {
          linkedinUrl
          instagramUrl
          youtubeUrl
        }
      }
    }
  }
`

const SocialMedia = () => {
  const data = useStaticQuery(getData)
  return (
    <SocialMediaStyled>
      <ul>
        {/* <StyledIcon>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.siteWideSettings.acfSiteWideSettings.linkedinUrl
            }
          >
            <Instagram />
          </a>
        </StyledIcon> */}
        <StyledIcon>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.siteWideSettings.acfSiteWideSettings.linkedinUrl
            }
          >
            <Linkedin />
          </a>
        </StyledIcon>
        <StyledIcon>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              data.socialIcons.siteWideSettings.acfSiteWideSettings.youtubeUrl
            }
          >
            <Youtube />
          </a>
        </StyledIcon>
      </ul>
    </SocialMediaStyled>
  )
}

const SocialMediaStyled = styled.div`
  display: none;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: calc(100%);
  }

  @media (min-width: 1025px) {
    width: calc(40% - 20rem);
    margin-left: 20rem;
    justify-content: flex-start;
  }

  @media (min-width: 1200px) {
    width: calc(40% - 25rem);
    margin-left: 25rem;
  }
`

const StyledIcon = styled.li`
  display: inline-block;
  margin-right: 1rem;
  margin-left: 1rem;

  &:first-of-type {
    margin-left: 0;
  }

  a {
    display: block;
    position: relative;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    @media (min-width: 768px) {
      width: 2rem;
      height: 2rem;
    }
    @media (min-width: 1025px) {
      width: 2rem;
      height: 2rem;
    }

    svg {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: auto;
      transition: all 0.3s ease-out;
      fill: ${colors.white};

      @media (min-width: 768px) {
        width: 2rem;
        height: 2rem;
      }
      @media (min-width: 1025px) {
        width: 2rem;
        height: 2rem;
      }

      &:hover {
        fill: ${colors.colorTertiary};
      }
    }
  }
`

export default SocialMedia
