import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { colors, medWrapper } from "../styles/helpers"

import MainLogo from "./Logos/MainLogo"
import HeaderNav from "./Navigation/HeaderNav/HeaderNav"
import MobileNav from "./Navigation/MobileNav/MobileNav"
import SocialMedia from "./Navigation/SocialMedia/SocialMedia"

const Header = ({ siteTitle }) => {
  return (
    <HeaderStyled>
      <div className="navTop" />
      <div className="navBottom">
        <div className="wrapper">
          <div className="headLogoWrap">
            <h1>
              <Link to="/">
                <MainLogo />
                <span>{siteTitle}</span>
              </Link>
            </h1>
          </div>
          <SocialMedia />
          <HeaderNav />
          <MobileNav />
        </div>
      </div>
    </HeaderStyled>
  )
}

const HeaderStyled = styled.header`
  .wrapper {
    ${medWrapper};
    position: relative;
    align-items: center;
    align-self: center;
    padding-top: 4rem;
    padding-bottom: 4rem;

    @media (min-width: 768px) {
      padding-bottom: 2rem;
    }
  }

  .headLogoWrap {
    width: 100%;
    max-width: 20rem;
    background-color: ${colors.white};
    z-index: 100;

    @media (min-width: 768px) {
      position: relative;
      max-width: 15rem;
      margin-bottom: 2.5rem;
    }

    @media (min-width: 1025px) {
      position: absolute;
      top: -3.5rem;
      left: 0;
      max-width: 20rem;
      margin-bottom: 0;
    }

    @media (min-width: 1200px) {
      max-width: 25rem;
    }

    .gatsby-image-wrapper {
      max-width: 25rem;
    }

    h1 {
      margin: 0;

      span {
        position: absolute;
        left: -999%;
      }
    }
  }

  .navTop {
    width: 100%;
    height: 3.5rem;
    background-color: ${colors.colorPrimary};
  }

  .navBottom {
    width: 100%;
    background-color: ${colors.colorSecondary};
  }
`

export default Header
