import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, Nav1White } from "../../../styles/helpers"

import HeaderSubMenu from "./HeaderSubMenu"

const HeaderNavItem = ({ item }) => {
  const slug = item.url
    .split("/")
    .filter(item => item !== "")
    .join("/")
  const [subActive, setSubActive] = useState(false)

  const handleIsActiveOn = () => {
    setSubActive(true)
  }

  const handleIsActiveOff = () => {
    setSubActive(false)
  }

  return (
    <HeaderNavItemStyled>
      <Link
        to={`/${slug}`}
        onMouseEnter={handleIsActiveOn}
        onMouseLeave={handleIsActiveOff}
      >
        {item.label}
      </Link>
      {item.subItems.length > 0 && (
        <HeaderSubMenu
          handleIsActiveOn={handleIsActiveOn}
          handleIsActiveOff={handleIsActiveOff}
          subActive={subActive}
          items={item.subItems}
        />
      )}
    </HeaderNavItemStyled>
  )
}

const HeaderNavItemStyled = styled.li`
  position: relative;
  align-self: center;

  a {
    ${Nav1White};
    margin: 0 1.5rem;
    text-transform: uppercase;

    &[aria-current="page"] {
      color: ${colors.colorTertiary};
      box-shadow: 0 1rem 0 0 ${colors.colorTertiary};

      &:hover {
        color: ${colors.colorTertiary};
        cursor: default;
      }
    }
  }
`

export default HeaderNavItem
