import { colors } from "./index"
import { fonts, fontSizer } from "./index"
import { css } from "styled-components"

// Headline Styles #1 //
export const H1Base = css`
  ${fontSizer(2.8, 4.5, 76.8, 150, 3.0)};
  font-family: ${fonts.fontPrimary};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`
export const H1White = css`
  ${H1Base};
  color: ${colors.white};
`

export const H1Blue = css`
  ${H1Base};
  color: ${colors.colorPrimary};
`

// Headline Styles #2 //
export const H2Base = css`
  ${fontSizer(2, 3.2, 76.8, 150, 2.4)}
  font-family: ${fonts.fontPrimary};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
`

export const H2White = css`
  ${H2Base};
  color: ${colors.white};
`

export const H2Green = css`
  ${H2Base};
  color: ${colors.colorSecondary};
`

// Headline Styles #3 //
export const H3Base = css`
  ${fontSizer(1.8, 2.2, 76.8, 150, 2)}
  font-family: ${fonts.fontPrimary};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
`

export const H3Gold = css`
  ${H3Base};
  color: ${colors.colorTertiary};
`

// Headline Styles #4 //
export const H4Base = css`
  ${fontSizer(2, 2.4, 76.8, 160, 2)};
  font-family: ${fonts.fontPrimary};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
`

// Headline Styles #5 //
export const HCalloutBase = css`
  ${fontSizer(2.2, 3, 76.8, 160, 2.2)};
  font-family: ${fonts.fontPrimary};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
`

export const HCalloutGreen = css`
  ${HCalloutBase};
  color: ${colors.colorSecondary};
`
